import React, { useState, useEffect } from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Box,
} from "@material-ui/core";
import * as constants from "../../constants";
import ExposeSubscriptionStepOne from "./ExposeSubscriptionStepOne";
import ExposeSubscriptionStepTwo from "./ExposeSubscriptionStepTwo";
import ExposeSubscriptionSuccess from "./ExposeSubscriptionSuccess";
import ExposeDialog from "./ExposeDialog";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { phone as validatePhoneNumber } from 'phone';
import ExposeSubscriptionFailed from "./ExposeSubscriptionFailed";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    [theme.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 0px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 2px 2px -1px rgba(0,0,0,0.12)",
    },
  },
  dialogActions: {
    [theme.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 2px rgba(0,0,0,0.2), 0px 1px 2px 2px rgba(0,0,0,0.14), 0px 2px 2px 1px rgba(0,0,0,0.12)",
    },
  },
}));

const blacklistedNumbers = ["01511234567"];

const ExposeSubscription = ({
  object_id,
  object_price,
  object_title,
  object_provision,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [resultOne, setResultOne] = useState(null);
  const [resultTwo, setResultTwo] = useState(null);
  const [values, setValues] = useState({
    salutation: "",
    email: "",
    firstname: "",
    lastname: "",
    street: "",
    zip: "",
    city: "",
    phone: "",
    accept_privacy: false,
    newsletter: false,
  });
  const [verifySms, setVerifySms] = useState({
    verifySms_1: "",
    verifySms_2: "",
    verifySms_3: "",
    verifySms_4: "",
  });
  const [verifySmsFocus, setVerifySmsFocus] = useState(1);
  const [verifySmsToken, setVerifySmsToken] = useState("");
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [blacklisted, setBlacklisted] = useState(false);
  const websiteSettings = useSelector((state) => state.settings);

  useEffect(() => {
    const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (
      numbers.indexOf(verifySms.verifySms_1) !== -1 &&
      numbers.indexOf(verifySms.verifySms_2) !== -1 &&
      numbers.indexOf(verifySms.verifySms_3) !== -1 &&
      numbers.indexOf(verifySms.verifySms_4) !== -1
    ) {
      handleVerifySms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifySms]);

  const handleReset = () => {
    setResultOne(null);
    setResultTwo(null);
    setValues({
      salutation: "",
      email: "",
      firstname: "",
      lastname: "",
      street: "",
      zip: "",
      city: "",
      phone: "",
      accept_privacy: false,
      newsletter: false,
      sellProperty: false,
    });
    setVerifySms({
      setVerifySms_1: "",
      setVerifySms_2: "",
      setVerifySms_3: "",
      setVerifySms_4: "",
    });
    setVerifySmsFocus(1);
    setVerifySmsToken("");
    setBlacklisted(false);
  };

  const handleChangeValidateSms = (e) => {
    const id = e.target.id;
    const val = e.target.value;
    const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const isValid = val === "" || numbers.indexOf(val) !== -1;
    const hasValue = numbers.indexOf(val) !== -1;
    if (!isValid) {
      return;
    }

    let focus = 1;

    switch (id) {
      case "verifySms_1":
        focus = hasValue ? 2 : 1;
        break;
      case "verifySms_2":
        focus = hasValue ? 3 : 2;
        break;
      case "verifySms_3":
        focus = hasValue ? 4 : 3;
        break;
      case "verifySms_4":
        focus = hasValue ? 0 : 4;
        break;
      default:
        focus = 0;
        break;
    }

    setVerifySms({ ...verifySms, [id]: val });
    setVerifySmsFocus(focus);
  };

  const handleChangeInput = (e) => {
    const id = e.target.id;
    const val = e.target.value;

    setValues({ ...values, [id]: val });
  };

  const handleChangeSelect = (e) => {
    const id = e.target.name;
    const val = e.target.value;
    setValues({ ...values, [id]: val });
  };

  const handleChangeCheckbox = (e, prop) => {
    const isChecked = e.target.checked;

    setValues(() => ({
      ...values,
      [prop]: isChecked,
    }));
  };

  const handleChangeNewsletter = (e) => {
    setValues((current) => ({ ...values, newsletter: !current.newsletter }));
  };

  const handleSubscribe = (event) => {
    const {
      salutation,
      email,
      firstname,
      lastname,
      street,
      zip,
      city,
      phone,
      accept_privacy,
      newsletter,
      sellProperty,
    } = values;

    if (blacklistedNumbers.includes(phone)) {
      setBlacklisted(true);
      return;
    }

    const phoneValidation = validatePhoneNumber(phone, { country: 'DE', validateMobilePrefix: true });

    if (phoneValidation.isValid) {
      setInvalidPhone(false);
    } else {
      setInvalidPhone(true);
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "user", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        salutation,
        email,
        firstname,
        lastname,
        street,
        zip,
        city,
        phone_prefix: '',
        phone_number: phoneValidation.phoneNumber.replace(/ /g, ''),
        accept_privacy,
        object_id,
        newsletter,
        object_title,
        object_price,
        object_provision,
        sellProperty,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setResultOne(data ? data.result : null);
        setVerifySmsToken(data ? data.token : null);
      });
  };

  const handleVerifySms = (event) => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "user/verify-sms", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        verifySmsToken,
        verifySms_1: verifySms.verifySms_1,
        verifySms_2: verifySms.verifySms_2,
        verifySms_3: verifySms.verifySms_3,
        verifySms_4: verifySms.verifySms_4,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setResultTwo(data ? data.result : null);
        if (data && data.result === "error") {
          setVerifySms({
            verifySms_1: "",
            verifySms_2: "",
            verifySms_3: "",
            verifySms_4: "",
          });
          setVerifySmsFocus(1);
        }
      });
  };

  const handleClickOpen = (event) => {
    handleReset();
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
    handleReset();

    if (
      values.sellProperty &&
      resultOne === "success" &&
      resultTwo === "success"
    ) {
      history.push("/immobilienbewertung");
    } else {
      history.push("/suchagent");
    }
  };

  const valid =
    object_id &&
    values.salutation &&
    values.email &&
    values.firstname &&
    values.lastname &&
    values.street &&
    values.zip &&
    values.city &&
    values.phone &&
    values.accept_privacy;

  const contentOne = (
    <ExposeSubscriptionStepOne
      invalidPhone={invalidPhone}
      handleChangeInput={handleChangeInput}
      handleChangeSelect={handleChangeSelect}
      handleChangeCheckbox={handleChangeCheckbox}
      handleChangeNewsletter={handleChangeNewsletter}
      salutation={values.salutation}
      email={values.email}
      firstname={values.firstname}
      lastname={values.lastname}
      street={values.street}
      zip={values.zip}
      city={values.city}
      phone_number={values.phone}
      accept_privacy={values.accept_privacy}
      result={resultOne}
      newsletter={values.newsletter}
      sellProperty={values.sellProperty}
    />
  );

  const contentTwo = (
    <ExposeSubscriptionStepTwo
      handleChangeInput={handleChangeValidateSms}
      verifySms_1={verifySms.verifySms_1}
      verifySms_2={verifySms.verifySms_2}
      verifySms_3={verifySms.verifySms_3}
      verifySms_4={verifySms.verifySms_4}
      verifySmsFocus={verifySmsFocus}
      result={resultTwo}
    />
  );

  const contentSuccess = <ExposeSubscriptionSuccess email={values.email} />;

  let content = null;
  if (blacklisted) { 
    content = <ExposeSubscriptionFailed phoneNumber={values.phone} />;
  } else if (resultOne === "success" && resultTwo === "success") {
    content = contentSuccess;
  } else if (resultOne !== "success") {
    content = contentOne;
  } else if (resultOne === "success") {
    content = contentTwo;
  }

  return (
    <>
      <div className={classes.btnWrapper}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleClickOpen}
        >
          {websiteSettings.show_expose_button}
        </Button>
      </div>
      <ExposeDialog open={open} handleClose={handleClose}>
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          Ausführliches Exposé anzeigen
        </DialogTitle>
        <DialogContent>
          <Box pb={2} pt={1}>
            {content}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleClose}
            color="primary"
            disableFocusRipple={true}
          >
            {resultOne === "success" && resultTwo === "success"
              ? "Schließen"
              : "Abbrechen"}
          </Button>
          {resultOne !== "success" && !blacklisted && (
            <Button
              variant="contained"
              onClick={handleSubscribe}
              color="primary"
              disabled={!valid}
            >
              Weiter
            </Button>
          )}
        </DialogActions>
      </ExposeDialog>
    </>
  );
};

export default ExposeSubscription;
