import { BrowserRouter, Switch, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { connect } from "react-redux";

/** external imports */
import ScrollMemory from "react-router-scroll-memory";
import { ThemeProvider } from "@material-ui/styles";

/** internal imports */
import NewsletterConfirmation from "./components/pages/NewsletterConfirmation";
import PropertyValuation from "./components/pages/property-valuation";
import SearchAgent from "./components/search-agent/SearchAgent";
import ArchivedDetail from "./components/pages/ArchivedDetail";
import { setSettings } from "./store/actions/settingsActions";
import AngebotDetail from "./components/pages/OfferDetail";
import Archived from "./components/pages/Archived";
import Angebote from "./components/pages/Offers";
import Reviews from "./components/pages/Reviews";
import Article from "./components/pages/Article";
import Approval from "./components/pages/Approval";
import Layout from "./components/layout/Layout";
import Expose from "./components/pages/Expose";
import Page from "./components/pages/Page";
import Home from "./components/pages/Home";
import Team from "./components/pages/Team";
import * as constants from "./constants";
import Widerrufsrecht from "./components/pages/Widerrufsrecht";
import VideoCreation from "./components/pages/VideoCreation";
import SalesPage from "./components/pages/SalesPage/SalesPage";
import Lexicon from "./components/pages/Lexicon/Lexicon";
import ActiveBlogsOverview from "./components/pages/ActiveBlogsOverview";
import InactiveBlogsOverview from "./components/pages/InactiveBlogsOverview";
import Decluttering from "./components/pages/Decluttering";
import NewConstruction from "./components/pages/NewConstruction";

function App({ setSettings }) {
  useEffect(() => {
    setSettings();
  }, [setSettings]);

  return (
    <BrowserRouter>
      <ScrollMemory />
      <ThemeProvider theme={constants.THEME}>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/bewertungen" component={Reviews} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/freigabe" component={Approval} />
            <Route exact path="/neubau" component={NewConstruction} />
            <Route exact path="/immobilie-verkaufen" component={SalesPage} />
            <Route exact path="/immobilienlexikon" component={Lexicon} />
            <Route
              exact
              path="/immobilienbewertung"
              component={PropertyValuation}
            />
            <Route exact path="/suchagent" component={SearchAgent} />
            <Route exact path="/widerrufsrecht" component={Widerrufsrecht} />
            <Route exact path="/fachwissen" component={ActiveBlogsOverview} />
            <Route exact path="/entrümpelung-und-räumung" component={Decluttering} />
            <Route
              exact
              path="/fachwissen-vorschau"
              component={InactiveBlogsOverview}
            />
            <Route
              exact
              path="/newsletter-confirmation/:token"
              component={NewsletterConfirmation}
            />
            <Route exact path="/immobilien/angebote" component={Angebote} />
            <Route path="/immobilien/angebot/:id" component={AngebotDetail} />
            <Route exact path="/expose/agb/:token" component={Expose} />
            <Route exact path="/expose/:token" component={AngebotDetail} />
            <Route exact path="/immobilien/verkauft" component={Archived} />
            <Route exact path="/videoerstellung" component={VideoCreation} />
            <Route
              exact
              path="/immobilien/verkauft/:id"
              component={ArchivedDetail}
            />
            <Route exact path="/:slug" component={Page} />
            <Route exact path="/fachwissen/:slug" component={Page} />
            <Route exact path="/fachwissen-vorschau/:slug" component={Page} />
            <Route exact path="/aktuelles/:slug" component={Article} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSettings: () => dispatch(setSettings()),
  };
};

export default connect(null, mapDispatchToProps)(App);
